<template>
  <div>
    <!-- class borderColorBackground -->
    <div class="borderColor mb-3">
      <b-alert
        :show="!otherInfoData"
        variant="secondary"
        class="text-center font-weight-bold m-5px"
      >
        目前暫無資料
      </b-alert>
      <div class="px-2 px-sm-3 row row-cols-1 row-cols-lg-2 row-cols-xl-3">
        <div
          v-for="(info, infoKey, index) in otherInfoData"
          :key="index"
          class="py-3 px-2 py-sm-4 px-sm-4"
        >
          <div class="title h4 mb-0 py-3 px-4">{{ infoKey }}</div>
          <div class="content py-3 px-4">
            <div class="content_table">
              <div
                v-for="(dataValue, dataKey, idx) in info"
                :key="idx"
                class="content_row"
              >
                <div class="content_title px-4 py-2">{{ dataKey }}</div>
                <div class="content_num py-2">{{ dataValue }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { BAlert } from 'bootstrap-vue'
export default {
  name: 'OtherInformation',
  components: { BAlert },
  data () {
    return {
      testData: {
        'HV-meter': {
          Freq: 59.98,
          I_a: 0.21,
          I_b: 0.24,
          I_c: 0.24,
          KVAR_tot: 4.69,
          KVA_tot: -4.19,
          P: -2.1,
          Vll_ab: 11.48,
          Vll_bc: 11.76,
          Vll_ac: 11.93,
          Vln_a: 11.48,
          Vln_b: 11.76,
          Vln_c: 11.93,
          I_n: 0,
          SBSPM: 0,
          PF: -0.47,
          kWh: 23494,
          CH_kWh: 37230
        },
        'LV-meter': {
          I_a: 0.21,
          I_b: 0.24,
          I_c: 0.24,
          I_n: 0,
          Vln_a: 11.48,
          Vln_b: 11.76,
          Vln_c: 11.93,
          Vll_ab: 11.48,
          Vll_bc: 11.76,
          Vll_ac: 11.93,
          SBSPM: 0,
          Freq: 59.98,
          P: -2.1,
          KVAR_tot: 4.69,
          KVA_tot: -4.19,
          PF: -0.47,
          kWh: 23494,
          CH_kWh: 37230
        },
        風速計: {
          Vll_ab: 11.48,
          Vll_bc: 11.76,
          Vll_ac: 11.93,
          SBSPM: 0,
          Freq: 59.98,
          P: -2.1,
          KVAR_tot: 4.69,
          KVA_tot: -4.19,
          PF: -0.47,
          kWh: 23494,
          CH_kWh: 37230
        }
      }
    }
  },
  computed: {
    ...mapState(['factoryData']),
    urlSiteName () {
      return this.$route.query.siteName
    },
    otherInfoData () {
      const siteData = this.factoryData.filter(
        (x) => x?.factoryName === this.urlSiteName // 用 factoryName 去比對，不用 displaySiteName，展示案場無此頁
      )

      // 有 detail.etc 或 detail.Anemometer
      if (
        siteData?.length &&
        (siteData?.[0]?.etc || siteData?.[0]?.Anemometer)
      ) {
        const dataKeyList = [
          'HV-meter',
          'LV-meter',
          'Sepam S40',
          'Shihlin SPM3'
          // 可能有多個
          // 'Multimeters01',
          // 'Multimeters02',...
          // 'ProtectionRelay01'
          // 'ProtectionRelay02'...
        ]
        const data = Object.keys(siteData[0].etc)
          .filter(
            (dataKey) =>
              dataKeyList.includes(dataKey) ||
              dataKey.includes('Multimeters') ||
              dataKey.includes('ProtectionRelay')
          )
          .reduce((accu, curr) => {
            if (!accu[curr]) accu[curr] = siteData[0].etc[curr]
            return accu
          }, {})

        // 有風速計 可能是單一數值 或 陣列 ex: 3.3 or [3.3,2.8,...]
        const anemometer = siteData?.[0]?.Anemometer
        if (typeof anemometer === 'number') {
          // 單一數值
          data['Anemometer'] = { Anemometer: anemometer }
        } else if (Array.isArray(anemometer)) {
          // 陣列數值
          data['Anemometer'] = anemometer.reduce((accu, curr, index) => {
            accu['Anemometer' + (index + 1).toString().padStart(2, '0')] = curr
            return accu
          }, {})
        }

        return Object.keys(data)?.length ? data : null // data 可能有 etc list 或 anemometer (list) 也可能都沒有
      }

      return null
    }
  },
  methods: {
    ...mapActions(['asyncLastSingleDoc']),
    ...mapMutations(['ADD_QUERY_SITE_NAME'])
  },
  async created () {
    if (!this.factoryData.length) {
      await this.asyncLastSingleDoc(this.urlSiteName)
    }
  }
}
</script>

<style lang="scss" scoped>
$blue_dark: #004886;
.title {
  font-weight: bold;
  color: #fff;
  border-radius: 5px 5px 0 0;
  background: linear-gradient(180deg, #3b679e 0%, #207cca 49.48%, #7db9e8 100%);
}
.content {
  border: solid 2px #569fdc;
  border-top: 0px;
  border-radius: 0 0 5px 5px;
}

.content_table {
  border: solid 2px $blue_dark;
  font-size: 20px;
  font-weight: 600;
}

.content_row {
  display: flex;
  border-bottom: 1px solid $blue_dark;
  &:last-child {
    border-bottom: 0px;
  }
}
.content_title {
  width: 45%;
  color: $blue_dark;
  background-color: #c0e3ff;
}

.content_num {
  width: 55%;
  text-align: center;
  background-color: #def6ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
